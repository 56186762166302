@import '../variables.css';

.overlay {
  margin: var(--space-medium);
  display: flex;
  flex-direction: column;
  gap: var(--space-medium);
}

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subpage {
  justify-content: center;
  align-items: center;
  padding-left: var(--space-xlarge);
  padding-right: var(--space-xlarge);
}