@import '../variables.css';

.page-button {
  --half-width: calc(var(--content-width) / 2);
  --half-height: calc(var(--content-height) / 2);
  --page-button-height: var(--space-medium);
  --page-button-width: var(--space-medium);
  /* i think i did my math wrong lol */
  --half-page-button-height: calc(var(--page-button-height));
  --half-page-button-width: calc(var(--page-button-width));

  position: absolute;
  background-color: var(--color-accent);
  padding: var(--space-small);
  cursor: pointer;
  width: var(--page-button-width);
  height: var(--page-button-height);
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: var(--border-size-small) solid var(--color-white-3); */
}

.page-button:hover {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.page-button-left {
  margin-top: calc(var(--half-height) - var(--half-page-button-height));
  border-top-right-radius: var(--space-small);
  border-bottom-right-radius: var(--space-small);
}
.page-button-up {
  margin-left: calc(var(--half-width) - var(--half-page-button-width));
  border-bottom-left-radius: var(--space-small);
  border-bottom-right-radius: var(--space-small);
}
.page-button-right {
  margin-top: calc(var(--half-height) - var(--half-page-button-height));
  margin-left: calc(var(--content-width) - (2 * var(--half-page-button-width)));
  border-top-left-radius: var(--space-small);
  border-bottom-left-radius: var(--space-small);
}
.page-button-down {
  margin-top: calc(var(--content-height) - (2 * var(--half-page-button-height)));
  margin-left: calc(var(--half-width) - var(--half-page-button-width));
  border-top-left-radius: var(--space-small);
  border-top-right-radius: var(--space-small);
}