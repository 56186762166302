:root {
  /* Screen scaling variables */
  --standard-width: 600;
  --standard-height: 400;
  --scaling-factor: calc(100vh / var(--standard-height)); 
  --content-width: calc(var(--standard-width) * var(--scaling-factor));
  --content-height: calc(var(--standard-height) * var(--scaling-factor));

  /* Spacing variables */
  --space-xsmall: calc(2 * var(--scaling-factor));
  --space-small: calc(4 * var(--scaling-factor));
  --space-medium: calc(8 * var(--scaling-factor));
  --space-large: calc(16 * var(--scaling-factor));
  --space-xlarge: calc(32 * var(--scaling-factor));

  /* Miscellaneous size variables */
  --border-radius-small: var(--space-small);
  --border-radius-large: var(--space-medium);
  --border-size-small: calc(1.5 * var(--scaling-factor));
  --border-size-large: calc(3 * var(--scaling-factor));

  /* Colors */
  --color-foreground-0: #228CDB;
  --color-foreground-1: #1899D6;
  --color-foreground-2: #1CB0F6;
  --color-foreground-3: #0b5375;
  --color-accent: #9C95DC;
  --color-white-0: #FFFFFF;
  --color-white-1: #D9DDDC;
  --color-white-2: #AEADA8;
  --color-white-3: #404241;
  --color-black: #222021;
  --color-text-shadow: #558ABB;
}