@import 'variables.css';

.page {
  background-color: var(--color-white-3);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-family: "Bubblegum Sans", sans-serif;
  color: #FFFFFF;
  font-size: var(--space-medium);
  font-weight: 500;
}

.content {
  background-color: var(--color-foreground-0);
  width: var(--content-width);
  height: var(--content-height);
}