@import '../variables.css';

.factory-card {
  /* border: var(--border-size-large) solid var(--color-white-2); */
  border-radius: var(--border-radius-large);
  background-color: var(--color-accent);
  color: var(--color-black);
  box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
}

.factory-card__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-large);
  /* width: 100%; */
}

.factory-card__upgrade__title {
  text-wrap: nowrap;
  font-weight: bold;
}