body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Bubblegum Sans", sans-serif;
  color: #FFFFFF;
  font-size: 30px;
  /* font-weight: 900; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  all: unset;
}

h1 {
  text-shadow: 5px 5px var(--color-text-shadow);
  margin: 0;
  letter-spacing: 1.5px;
}

h2 {
  text-shadow: 2px 2px var(--color-text-shadow);
  margin: 0;
  letter-spacing: 0.6px;
}

h3 {
  margin: 0;
}